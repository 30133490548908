import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Flex,
  Box,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Stack,
  Textarea,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { CollaborationsData, InfluencerCampaign } from '../../types';
import { counterCollab, getCampaignsFromIDs } from '../../../../../services/firebaseService';
import { sendCounterText } from '../../../../../services/flaskService';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import CollabDetailsView from '../CollabDetailsView';

interface CounterBackCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  collab: CollaborationsData;
  campaignId: string;
  clientName: string;
}

const CounterBackCollabModal: React.FC<CounterBackCollabModalProps> = ({ isOpen, onClose, onUpdate, collab, campaignId, clientName }) => {
  const [tikTokCount, setTikTokCount] = useState(collab?.proposedInfluencerDeliverableCounts?.tiktoks || 0);
  const [instagramStoryCount, setInstagramStoryCount] = useState(collab?.proposedInfluencerDeliverableCounts?.instagramStories || 0);
  const [instagramReelCount, setInstagramReelCount] = useState(collab?.proposedInfluencerDeliverableCounts?.instagramReels || 0);
  const [instagramPostCount, setInstagramPostCount] = useState(collab?.proposedInfluencerDeliverableCounts?.instagramPosts || 0);
  const [cash, setCash] = useState(collab?.proposedInfluencerCash?.toString() || '0');
  const [credit, setCredit] = useState(collab?.proposedInfluencerCredit?.toString() || '0');

  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const campaign = influencerCampaigns[campaignId];

  const influencers = useContext(InfluencersDataContext);
  const influencer = influencers[collab.influencerID];

  const toast = useToast();

  const handleDeliverableChange = (type: string, value: number) => {
    switch (type) {
      case 'tiktoks':
        setTikTokCount(value);
        break;
      case 'instagramStories':
        setInstagramStoryCount(value);
        break;
      case 'instagramReels':
        setInstagramReelCount(value);
        break;
      case 'instagramPosts':
        setInstagramPostCount(value);
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      await counterCollab(collab.id, {
        tiktoks: tikTokCount,
        instagramStories: instagramStoryCount,
        instagramReels: instagramReelCount,
        instagramPosts: instagramPostCount,
      }, Number(cash), Number(credit));
      sendCounterText(influencer.phoneNumber, influencer.firstName, clientName);
      toast({
        title: "Collab countered",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onUpdate();
      onClose();
    } catch (error) {
      console.error("Error submitting counter:", error);
    }
  };

  const isSubmitDisabled = () => {
    const totalDeliverables = tikTokCount + instagramStoryCount + instagramReelCount + instagramPostCount;
    const totalCashAndCredit = Number(cash) + Number(credit);
    return totalDeliverables === 0 || totalCashAndCredit === 0;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"} border="2px solid green.500">
        <ModalHeader>Campaign Details</ModalHeader>
        <ModalBody>
          <CollabDetailsView
            campaign={campaign}
            deliverableCounts={{
              tiktoks: tikTokCount,
              instagramStories: instagramStoryCount,
              instagramReels: instagramReelCount,
              instagramPosts: instagramPostCount
            }}
            onDeliverableChange={handleDeliverableChange}
            cash={cash}
            credit={credit}
            onCashChange={setCash}
            onCreditChange={setCredit}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={handleSubmit}
            isDisabled={isSubmitDisabled()}
          >
            Submit Counter
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CounterBackCollabModal;
