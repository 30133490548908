import { FoodfluenceLocationsDataContext } from 'contexts/foodfluenceLocationsDataContext';
import moment from 'moment-timezone';
import { useContext } from 'react';
import { FoodfluenceLocation } from '../types';

/**
 * Gets a formatted timezone-specific date display string
 * @param location - The location object to get timezone for
 * @param date - The date to format
 * @returns Formatted date string with timezone
 */
export const getTimezoneDateDisplay = (location: FoodfluenceLocation, date: Date): string => {
  // Default to local timezone if no location timezone found
  let timezone = location?.timezone || moment.tz.guess();
  
  // Format the date in the specified timezone
  const momentDate = moment(date).tz(timezone);
  
  // Format: "Thu, Jan 1 @ 2:00 PM PST"
  return momentDate.format('ddd, MMM D, h:mm A z');
};

/**
 * Checks if two dates are the same day
 * @param date1 - First date to compare
 * @param date2 - Second date to compare
 * @returns Boolean indicating if dates are the same day
 */
export const isSameDate = (date1: Date, date2: Date): boolean => {
  if (!date1 || !date2) return false;
  
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
};