import { useContext, useState, useEffect } from "react";
import { SuperUserDataContext } from "contexts/superUserDataContext";
import { Box, Button, Flex, Grid, IconButton, Spinner, Text, useToast, Menu, MenuButton, MenuList, MenuItem, Checkbox } from "@chakra-ui/react";
import AddAdminAccountModal from "../../views/superUser/addAdminAccountModal";
import Environment from "environment";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import CompanyStats from "views/superUser/companyStats";
import ReviewContests from "views/superUser/reviewContests/reviewContests";
import { createDashboardAdminAccount } from "services/flaskService";
import { ActiveUidContext } from "contexts/activeUidContext";
import { FaHome, FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import ReviewContestsDataProvider from "contexts/reviewContestsDataContext";
import GlobalCollabCalendar from '../../views/superUser/GlobalCollabCalendar';

// Add this type for platform selection
type Platform = 'instagram' | 'tiktok' | 'both';

export default function SuperUserLayout(props: { [x: string]: any }) {
  const superUserData = useContext(SuperUserDataContext);
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [upcomingCollabsCount, setUpcomingCollabsCount] = useState(0);
  const [worksheets, setWorksheets] = useState<string[]>([]);
  const [selectedWorksheets, setSelectedWorksheets] = useState<string[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const { uid, setUid } = useContext(ActiveUidContext);
  const [user] = useAuthState(auth);

  // Add state for selected platform
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>('both');

  useEffect(() => {
    fetchWorksheets(); // Fetch worksheets when the component mounts
  }, []);

  async function handleAccountCreation(email: string, password: string, accountName: string) {
    setIsAddingAccount(true);
    try {
      let data = await createDashboardAdminAccount(email, password, accountName);
      if (data !== null) {
        setIsAddAccountModalOpen(false);
        console.log(data);
        toast({
          title: "Account Created",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
      }
    } catch (error) {
      setIsAddAccountModalOpen(false);
      console.error("Error creating dashboard account:", error);
      toast({
        title: "FAILED to create account right now",
        status: "error",
        duration: 1250,
        isClosable: false,
      });
    }
    setIsAddingAccount(false);
  }

  // Function to fetch available worksheets
  const fetchWorksheets = async () => {
    try {
      const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/get-worksheets`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': Environment.FLASK_API_KEY // Include authorization if needed
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch worksheets');
      }

      const data = await response.json();
      setWorksheets(data.worksheets); // Set the worksheets state
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch worksheets",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Modify the refresh function to handle both platforms
  const handleRefreshInfluencerData = async () => {
    if (selectedWorksheets.length === 0) {
      toast({
        title: "Error",
        description: "Please select at least one worksheet",
        status: "error",
        duration: null,
        isClosable: true,
      });
      return;
    }

    setIsRefreshing(true);
    try {
      const endpoints = [];
      if (selectedPlatform === 'instagram' || selectedPlatform === 'both') {
        endpoints.push(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/run-refresh-influencer-data/`);
      }
      if (selectedPlatform === 'tiktok' || selectedPlatform === 'both') {
        endpoints.push(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/update-all-tiktok/`);
      }

      const results = await Promise.all(endpoints.map(endpoint => 
        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Environment.FLASK_API_KEY
          },
          body: JSON.stringify({ worksheet_names: selectedWorksheets }),
        })
      ));

      const responses = await Promise.all(results.map(r => r.json()));
      
      // Check if any requests failed
      const anyFailed = responses.some(response => !response.success);
      
      toast({
        title: anyFailed ? "Partial Success" : "Success",
        description: anyFailed 
          ? "Some updates failed. Check console for details." 
          : "Successfully updated all selected platforms",
        status: anyFailed ? "warning" : "success",
        duration: null,
        isClosable: true,
      });

    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to refresh data",
        status: "error",
        duration: null,
        isClosable: true,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Switch>
      <Route exact path="/superuser/">
        <>
          <AddAdminAccountModal
            isOpen={isAddAccountModalOpen}
            handleAccountCreation={handleAccountCreation}
            isAddingAccount={isAddingAccount}
            onClose={() => {
              setIsAddAccountModalOpen(false);
            }}
          />
          <Box>
            <Box
              backgroundColor="white"
              borderRadius="10px"
              width="100%"
              height="80%"
              position="relative"
            >
              <Box
                position="absolute"
                mt="40px"
                left={5}
                width="100%"
                height="100%"
              >
                <Flex justifyContent="space-evenly" alignItems="center">
                  <IconButton aria-label="go-home" icon={<FaHome />} onClick={() => {
                    history.push("/admin/overview/");
                    setUid(user?.uid);
                  }} />
                  <Button
                    onClick={handleRefreshInfluencerData}
                    backgroundColor="black"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Open Manual Verification
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      history.push("/superuser/company-stats/");
                    }}
                    backgroundColor="black"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      View Company-Wide Stats
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      setIsAddAccountModalOpen(true);
                    }}
                    backgroundColor="black"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Create New Admin Account +
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      history.push("/superuser/review-contests");
                    }}
                    backgroundColor="black"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Review Contests
                    </Text>
                  </Button>

                  <Menu closeOnSelect={false}>
                    <MenuButton 
                      as={Button} 
                      rightIcon={<FaChevronDown />} 
                      colorScheme="blue" 
                      variant="outline"
                      mt="-17.5px"
                    >
                      Select Worksheets ({selectedWorksheets.length})
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <MenuItem>
                        <Checkbox
                          isChecked={selectedWorksheets.length === worksheets.length}
                          isIndeterminate={selectedWorksheets.length > 0 && selectedWorksheets.length < worksheets.length}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedWorksheets(worksheets);
                            } else {
                              setSelectedWorksheets([]);
                            }
                          }}
                        >
                          Select All
                        </Checkbox>
                      </MenuItem>
                      <Box borderBottom="1px" borderColor="gray.200" my={2} />
                      {worksheets.map((worksheet, index) => (
                        <MenuItem key={index}>
                          <Checkbox
                            isChecked={selectedWorksheets.includes(worksheet)}
                            onChange={(e) => {
                              e.stopPropagation();
                              setSelectedWorksheets(prev => {
                                const newSelection = [...prev];
                                const worksheetIndex = newSelection.indexOf(worksheet);
                                if (worksheetIndex > -1) {
                                  newSelection.splice(worksheetIndex, 1);
                                } else {
                                  newSelection.push(worksheet);
                                }
                                return newSelection;
                              });
                            }}
                          >
                            {worksheet}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  <Menu closeOnSelect={true}>
                    <MenuButton 
                      as={Button} 
                      rightIcon={<FaChevronDown />} 
                      colorScheme="blue" 
                      variant="outline"
                      mt="-17.5px"
                      ml={2}
                    >
                      Platform: {selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setSelectedPlatform('instagram')}>
                        Instagram
                      </MenuItem>
                      <MenuItem onClick={() => setSelectedPlatform('tiktok')}>
                        TikTok
                      </MenuItem>
                      <MenuItem onClick={() => setSelectedPlatform('both')}>
                        Both
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <Button
                    onClick={handleRefreshInfluencerData}
                    backgroundColor="green.500"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    isDisabled={isRefreshing}
                  >
                    <Flex align="center">
                      {isRefreshing ? (
                        <Spinner size="sm" color="white" mr={2} />
                      ) : null}
                      <Text
                        fontWeight="500"
                        fontSize="18px"
                        textAlign="center"
                        color="white"
                      >
                        {isRefreshing 
                          ? 'Refreshing...' 
                          : `Refresh ${selectedPlatform === 'both' ? 'All' : selectedPlatform}`}
                      </Text>
                    </Flex>
                  </Button>

                  <Box position="relative">
                    <IconButton
                      aria-label="View Calendar"
                      icon={<FaCalendarAlt color="white" />}
                      bg="black"
                      _hover={{ bg: "gray.300" }}
                      onClick={() => setIsCalendarModalOpen(true)}
                      size="lg"
                    />
                    {upcomingCollabsCount > 0 && (
                      <Box
                        position="absolute"
                        top="-8px"
                        right="-8px"
                        bg="red.500"
                        color="white"
                        borderRadius="full"
                        width="20px"
                        height="20px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="xs"
                        fontWeight="bold"
                      >
                        {upcomingCollabsCount}
                      </Box>
                    )}
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
                </Flex>
                <Flex alignItems="center">
                  <Text ml="10px" my="15px" fontWeight="bold" fontSize="25px">
                    Choose an admin account
                  </Text>
                </Flex>
                {superUserData?.length > 0 ? (
                  <Grid
                    templateColumns={{
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                      lg: "repeat(3, 1fr)",
                    }}
                    gap={6}
                  >
                    {superUserData.map((adminDoc: any, index: number) => (
                      <Box key={index} p="5" shadow="md" borderWidth="1px" onClick={() => {
                        history.push("/admin/overview/");
                        setUid(adminDoc.id);
                      }}>
                        <Text fontSize="xl">{adminDoc?.accountName}</Text>
                      </Box>
                    ))}
                  </Grid>
                ) : (
                  <Flex justify="center" align="center" height="100vh">
                    <Spinner />
                  </Flex>
                )}
              </Box>
            </Box>
          </Box>
          <GlobalCollabCalendar
            isOpen={isCalendarModalOpen}
            onClose={() => setIsCalendarModalOpen(false)}
            setUpcomingCollabsCount={setUpcomingCollabsCount}
          />
        </>
      </Route>
      <Route exact path="/superuser/company-stats">
        <CompanyStats />
      </Route>
      <Route exact path="/superuser/review-contests">
        <ReviewContestsDataProvider>
          <ReviewContests />
        </ReviewContestsDataProvider>
      </Route>
    </Switch>
  );
}
