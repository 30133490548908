import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  HStack,
  Avatar,
  Flex,
  IconButton,
  Link,
  Badge,
  Button
} from '@chakra-ui/react';
import moment from 'moment';
import { FaInstagram, FaEnvelope, FaTiktok } from 'react-icons/fa';

import { CollaborationsData, Influencer, InfluencerCampaign, collabStates, FoodfluenceLocation } from '../types';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { FoodfluenceLocationsDataContext } from 'contexts/foodfluenceLocationsDataContext';
import StatusButton from './StatusButton';
import SubmissionsModal from './SubmissionsModal';
import { getTimezoneDateDisplay } from '../utils/dateUtils';
import { filterAwaitingShipmentCollabs, filterPastCollabs, filterCanceledCollabs, filterUnconfirmedCollabs, filterUpcomingCollabs, filterCollabsByCampaign } from '../utils/collabsUtils';
interface CollabCalendarProps {
  campaignId?: string;
  isOpen: boolean;
  onClose: () => void;
  setUpcomingCollabsCount: (count: number) => void;
  global: boolean;
  businessType?: string;
  initialTab?: number;
  globalCollaborations?: Record<string, CollaborationsData>;
  globalCampaigns?: Record<string, InfluencerCampaign>;
  globalInfluencers?: Record<string, Influencer>;
  globalFoodfluenceLocations?: Record<string, FoodfluenceLocation>;
}

const CollabCalendar: React.FC<CollabCalendarProps> = ({ campaignId = null, isOpen, onClose, setUpcomingCollabsCount, global, businessType, initialTab = 0, globalCollaborations={}, globalCampaigns={}, globalInfluencers={}, globalFoodfluenceLocations={} }) => {
  const [currentTab, setCurrentTab] = useState(initialTab);


  const contextCampaigns = useContext(InfluencerCampaignsContext);
  const contextInfluencers = useContext(InfluencersDataContext);
  const contextFoodfluenceLocations = useContext(FoodfluenceLocationsDataContext);
  const contextCollaborations = useContext(CollabsDataContext);

  let campaigns: Record<string, InfluencerCampaign>,
      influencers: Record<string, Influencer>,
      foodfluenceLocations: Record<string, FoodfluenceLocation>,
      collaborations: Record<string, CollaborationsData>;

  if (global) {
    campaigns = globalCampaigns;
    influencers = globalInfluencers;
    foodfluenceLocations = globalFoodfluenceLocations;
    collaborations = globalCollaborations;
  } else {
    campaigns = contextCampaigns;
    influencers = contextInfluencers;
    foodfluenceLocations = contextFoodfluenceLocations;
    collaborations = campaignId ? filterCollabsByCampaign(contextCollaborations, campaignId) : contextCollaborations;
  }

  const awaitingShipmentCollabs = filterAwaitingShipmentCollabs(collaborations);

  const upcomingCollabs = filterUpcomingCollabs(collaborations);

  const unconfirmedCollabs = filterUnconfirmedCollabs(collaborations); 

  const pastCollabs = filterPastCollabs(collaborations, campaigns);

  const canceledCollabs = filterCanceledCollabs(collaborations);

  useEffect(() => {
    setUpcomingCollabsCount(upcomingCollabs.length);
  }, [upcomingCollabs.length]);


  const renderCollabList = (collabs: CollaborationsData[]) => (
    <VStack 
      align="stretch" 
      spacing={4} 
      overflowY="auto" 
      height="60vh"
      minHeight="60vh"
    >
      {collabs.map((collab) => {
        const influencer = influencers[collab.influencerID];
        return (
          <HStack key={collab.id} spacing={4} p={4} borderWidth={1} borderRadius="md">
            <Avatar 
              src={influencer?.profile_pic_url} 
              name={influencer?.instagramHandle}
              size="lg"
            />
            <Box flex={1}>
              <Flex alignItems="center">
                <Text fontWeight="bold">@{influencer?.instagramHandle}</Text>
                <Flex ml={2}>
                  {influencer?.instagramHandle && (
                    <IconButton
                      icon={<FaInstagram />}
                      aria-label="Instagram"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://instagram.com/${influencer.instagramHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                  {influencer?.tiktokHandle && (
                    <IconButton
                      icon={<FaTiktok />}
                      aria-label="TikTok"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://www.tiktok.com/@${influencer.tiktokHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                </Flex>
              </Flex>
              <Text fontSize="sm">
                {collab.status === 'collabCanceled' 
                  ? `Rejection Reason: ${collab.influencerRejectionReason || 'No reason provided'}`
                  : collab.status === 'pendingInfluencerApproval'
                    ? `Collab sent: ${moment(collab.collabReceivedTime.toDate()).format('ddd, MMM D, h:mm A')}`
                    : campaigns[collab.influencerCampaignID]?.campaignType=="shipToHome"
                      ? `Shipping Address: ${collab.shippingAddress || 'TBD'}`
                      : collab.scheduledCollabDate 
                        ? getTimezoneDateDisplay(foodfluenceLocations[collab.selectedLocation], collab.scheduledCollabDate.toDate())
                        : 'Awaiting influencer scheduling'
                }
              </Text>
              {foodfluenceLocations[collab.selectedLocation] && <Text fontSize="sm">{foodfluenceLocations[collab.selectedLocation].fullAddress}</Text>}
              {global && <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.clientName}</Text>}
              <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.name}</Text>
            </Box>
            <StatusButton
              status={collab.status}
              collaboration={collab}
              campaign={campaigns[collab.influencerCampaignID]}
              influencer={influencers[collab.influencerID]}
              size="sm"
            />
          </HStack>
        );
      })}
    </VStack>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="50vw">
          <ModalHeader>Collab Schedule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs index={currentTab} onChange={setCurrentTab}>
              <TabList>
                <Tab>Upcoming ({upcomingCollabs.length})</Tab>
                {!global && <Tab>Unconfirmed ({unconfirmedCollabs.length})</Tab>}
                {(global || businessType === 'hybrid' || businessType === 'online') && (
                  <Tab>Shipping ({awaitingShipmentCollabs.length})</Tab>
                )}
                <Tab>Past ({pastCollabs.length})</Tab>
                <Tab>Cancelled ({canceledCollabs.length})</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderCollabList(upcomingCollabs)}</TabPanel>
                {!global && <TabPanel>{renderCollabList(unconfirmedCollabs)}</TabPanel>}
                {(global || businessType === 'hybrid' || businessType === 'online') && (
                  <TabPanel>{renderCollabList(awaitingShipmentCollabs)}</TabPanel>
                )}
                <TabPanel>{renderCollabList(pastCollabs)}</TabPanel>
                <TabPanel>{renderCollabList(canceledCollabs)}</TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CollabCalendar;