import { Timestamp } from "firebase/firestore";
import CollabInviteView from "./components/CollabInviteView";
import CounteredCollabOptions from "./components/counter-flow/CounterCollabOptions";
import RejectedCollabModal from "./components/RejectedCollabModal";
import ShippingConfirmationModal from "./components/shippingConfirmationModal";
import SubmissionsModal from "./components/SubmissionsModal";
import UpcomingInStoreCollabModal from "./components/UpcomingInStoreCollabModal";
import UpdateTrackingModal from "./components/updateTrackingModal";

export interface Influencer {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    email_address: string;
    instagramHandle: string;
    tiktokHandle: string;
    state: string;
    city: string;
    profilePic: any;
    profile_pic_url: string;
    follower_count: number;
    featured: boolean;
    tags: string[];
    phoneNumber: string;
    engagement_rate: number;
    tiktokEngagementRate?: number;
    displayEngagement?: number;
    tiktokFollowers?: number;
 }

export interface OpenHours {
    Monday: Set<string>;
    Tuesday: Set<string>;
    Wednesday: Set<string>;
    Thursday: Set<string>;
    Friday: Set<string>;
    Saturday: Set<string>;
    Sunday: Set<string>;
}

export interface InfluencerCampaign {
    id: string;
    clientID: string;
    name: string;
    description: string;
    deliverables: any;
    cash: number;
    credit: number;
    startDate: Timestamp | null;
    endDate: Timestamp | null;
    collabImage: string;
    deliverableCounts: DeliverableCounts;
    openHours: OpenHours;
    foodfluenceLocations: string[];
    clientName: string;
    allowCounters: boolean;
    campaignType: string;
}

export interface CollaborationsData {
    id: string;
    influencerID: string;
    influencerCampaignID: string;
    cash: number;
    credit: number;
    deliverableCounts: DeliverableCounts;
    deliverableLinks: any;
    postMetrics: any;
    offerRedeemedDate: Date;
    logs: any;
    status: string;
    scheduledCollabDate: Timestamp;
    collabReceivedTime: Timestamp;
    collabCounteredTime: Timestamp;
    shippingAddress?: string;
    shippingConfirmed?: Timestamp;
    proposedInfluencerDeliverableCounts?: DeliverableCounts;
    proposedInfluencerCash?: number;
    proposedInfluencerCredit?: number;
    selectedLocation: string;
    trackingNumbers?: string[];
    influencerRejectionReason?: string;
}

export interface FoodfluenceLocation {
    docId: string;
    id: string;
    fullAddress: string;
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    lat: number;
    lng: number;
    timezone: string;
    emails: string[];
    clientID: string;
}

export interface DeliverableCounts {
    tiktoks: number;
    instagramReels: number;
    instagramStories: number;
    instagramPosts: number;
}

export const collabStates: Record<string, { label: string; color: string, component?: React.FC<any> }> = {
    pendingInfluencerApproval: {
      label: 'Pending Influencer Approval',
      color: 'gray.300',
      component: CollabInviteView
    },
    pendingRedemption: {
      label: 'Upcoming Collab',
      color: 'gray.300',
      component: UpcomingInStoreCollabModal
    },
    collabRedeemed: {
      label: 'Pending Reward Redemption',
      color: '#B8E79E',
    },
    pendingSubmission: {
      label: 'Awaiting Content Submission',
      color: 'gray.300',
    },
    collabCanceled: {
      label: 'Collab Declined',
      color: 'red.300',
      component: RejectedCollabModal
    },
    collabExpired: {
      label: 'Collab Expired',
      color: 'red.300',
    },
    collabCompleted: {
      label: 'Completed Collab',
      color: '#B8E79E',
      component: SubmissionsModal
    },
    influencerCountered: {
      label: 'View Counter',
      color: 'gray.300',
      component: CounteredCollabOptions
    },
    pendingShipmentConfirmation: {
      label: 'Confirm Shipment',
      color: '#B8E79E',
      component: ShippingConfirmationModal
    },
    pendingSubmissionShipToHome: {
      label: 'Tracking Info',
      color: '#B8E79E',
      component: UpdateTrackingModal
    }
  };