import React, { useContext } from 'react';
import { Box, Text, Flex, Icon, VStack, HStack, Avatar } from '@chakra-ui/react';
import { FaChevronRight, FaExclamationCircle } from 'react-icons/fa';
import { CollaborationsData, Influencer } from '../types';
import moment from 'moment-timezone';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { FoodfluenceLocationsDataContext } from 'contexts/foodfluenceLocationsDataContext';
import { getTimezoneDateDisplay } from '../utils/dateUtils';
import { filterUpcomingCollabs } from '../utils/collabsUtils';

interface UpcomingCollabsListProps {
  onViewAll: (tabIndex?: number) => void;
}

const UpcomingCollabsList: React.FC<UpcomingCollabsListProps> = ({ onViewAll }) => {
  const collaborations = useContext(CollabsDataContext);
  const influencers = useContext(InfluencersDataContext);
  const foodfluenceLocations = useContext(FoodfluenceLocationsDataContext);

  const sortedCollabs = filterUpcomingCollabs(collaborations);
  
  const renderCollabList = (collabs: CollaborationsData[]) => (
    <VStack 
      spacing={0} 
      align="stretch" 
      overflow="auto" 
      maxHeight="calc(300px - 60px)"
    >
      {collabs.map((collab) => {
        const influencer = influencers[collab.influencerID];
        let displayText;
        const needsShipping = collab.shippingAddress != null;
        if (needsShipping) {
          displayText = `📦 ${collab.shippingAddress}`;
        } else if (collab.scheduledCollabDate) {
          displayText = getTimezoneDateDisplay(foodfluenceLocations[collab.selectedLocation], collab.scheduledCollabDate.toDate());
        } else {
          displayText = 'Awaiting influencer scheduling';
        }
        const locationAddress = foodfluenceLocations[collab.selectedLocation]?.fullAddress;

        return (
          <Flex
            key={collab.id}
            p={4}
            borderBottom="1px solid"
            borderColor="gray.100"
            align="center"
            justify="space-between"
          >
            <HStack spacing={3}>
              <Avatar 
                size="sm"
                src={influencer?.profile_pic_url} 
                name={influencer?.instagramHandle}
              />
              <Box>
                <Text fontWeight="500">
                  @{influencer?.instagramHandle || 'Unknown'}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {displayText}
                </Text>
                {locationAddress && (
                  <Text fontSize="sm" color="gray.500">
                    {locationAddress}
                  </Text>
                )}
              </Box>
            </HStack>
            {needsShipping && (
              <Icon 
                as={FaExclamationCircle} 
                color="orange.500" 
                w={5} 
                h={5} 
                title="Priority: Needs Shipping"
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onViewAll(2);
                }}
                _hover={{ transform: 'scale(1.1)' }}
                transition="transform 0.2s"
              />
            )}
          </Flex>
        );
      })}

      {collabs.length === 0 && (
        <Flex p={4} justify="center" align="center">
          <Text color="gray.500">No collaborations found</Text>
        </Flex>
      )}
    </VStack>
  );

  return (
    <Box 
      bg="white" 
      borderRadius="xl" 
      border="1px solid"
      borderColor="gray.300"
      width="100%"
      height="300px"
      overflow="hidden"
    >
      <Flex 
        justify="space-between" 
        align="center" 
        p={4} 
        borderBottom="1px solid" 
        borderColor="gray.100"
      >
        <Text fontSize="lg" fontWeight="600">
          Upcoming Collabs ({sortedCollabs.length})
        </Text>
        <Box
          as="button"
          display="flex"
          alignItems="center"
          gap={1}
          px={3}
          py={1}
          borderRadius="full"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          onClick={() => onViewAll()}
        >
          <Text fontSize="sm" color="gray.600">All</Text>
          <Icon as={FaChevronRight} w={3} h={3} color="gray.600" />
        </Box>
      </Flex>

      {renderCollabList(sortedCollabs)}
    </Box>
  );
};

export default UpcomingCollabsList;
