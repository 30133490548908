import React from 'react';
import {
  Text,
  Box,
  VStack,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';

interface ShipmentInfoViewProps {
  campaign?: InfluencerCampaign;
  influencer?: Influencer;
  collab?: CollaborationsData;
}

const ShipmentInfoView: React.FC<ShipmentInfoViewProps> = ({
  campaign,
  influencer,
  collab,
}) => {
  return (
    <VStack spacing={6} align="stretch">
      <Box 
        p={4} 
        bg="gray.50"
        borderRadius="lg"
        border="1px"
        borderColor="gray.200"
      >
        <Text fontWeight="semibold" mb={2}>Campaign Details</Text>
        <Text fontSize="md" color="gray.700">{campaign?.name}</Text>
        <Text fontSize="sm" color="gray.600" mt={1}>{campaign?.description}</Text>
      </Box>

      <Box 
        p={4} 
        bg="gray.50"
        borderRadius="lg"
        border="1px"
        borderColor="gray.200"
      >
        <Text fontWeight="semibold" mb={2}>Influencer Details</Text>
        <Text fontSize="md" color="gray.700">
          {influencer?.firstName === "unknown" || influencer?.lastName === "unknown" 
            ? "Full name not known" 
            : `${influencer?.firstName} ${influencer?.lastName}`}
        </Text>
        <Flex 
          fontSize="sm" 
          color="gray.600" 
          mt={1}
          cursor="pointer"
          onClick={() => window.open(`https://instagram.com/${influencer?.instagramHandle}`, '_blank')}
          _hover={{ textDecoration: 'underline' }}
          align="center"
        >
          <Icon as={FaInstagram} mr={1} />
          @{influencer?.instagramHandle}
        </Flex>
      </Box>

      <Box 
        p={4} 
        bg="gray.50"
        borderRadius="lg"
        border="1px"
        borderColor="gray.200"
      >
        <Text fontWeight="semibold" mb={2}>Shipping Address</Text>
        <Text fontSize="md" color="gray.700">{collab?.shippingAddress}</Text>
      </Box>
    </VStack>
  );
};

export default ShipmentInfoView;
