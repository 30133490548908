import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  ModalCloseButton,
} from '@chakra-ui/react';

interface LaunchConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedCount: number;
  hasCashPayment: boolean;
}

const LaunchConfirmationModal: React.FC<LaunchConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedCount,
  hasCashPayment,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(10px)" />
      <ModalContent mx={4} bg="white" borderRadius="xl" boxShadow="xl" py={10}>
        <ModalCloseButton color="black" />
        <ModalHeader 
          textAlign="center" 
          fontSize="2xl" 
          fontWeight="bold"
          color="black"
        >
          Send Collaboration Invites
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center" py={4}>
            <Text fontSize="xl" textAlign="center">
              You're about to send collab invites to {selectedCount} influencer{selectedCount !== 1 ? 's' : ''}.
            </Text>
            <Text fontSize="lg" color="red.500" textAlign="center" fontWeight="medium">
              Once sent, invites cannot be canceled.
            </Text>
            {hasCashPayment && (
              <Text fontSize="md" color="gray.600" textAlign="center">
                Payment information will be required after confirmation.
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" gap={4}>
          <Button
            variant="ghost"
            onClick={onClose}
            _hover={{ bg: 'gray.100' }}
          >
            Cancel
          </Button>
          <Button
            bg="black"
            color="white"
            onClick={onConfirm}
            _hover={{ bg: 'gray.800' }}
            px={8}
          >
            Send Invites
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LaunchConfirmationModal;