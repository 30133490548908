import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Flex, Icon, SimpleGrid } from '@chakra-ui/react';
import { FaArrowUp, FaArrowDown, FaListUl, FaEye, FaChartLine, FaUsers } from 'react-icons/fa';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';

const Analytics = () => {
  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const influencers = useContext(InfluencersDataContext);

  const collaborations = useContext(CollabsDataContext);

  const getTotalPosts = () => {
    if (!collaborations) return 0;
  
    return Object.values(collaborations).reduce((total, collab) => {
      if (collab.status === 'collabCompleted') {

        const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
        return total + instagramPosts + instagramReels + instagramStories + tiktoks;
      }
      return total;
    }, 0);
  };

  const totalCreators = Object.values(collaborations || {}).length;
  
  const completedCollaborations = Object.values(collaborations || {}).filter(collab => collab.status === 'collabCompleted').length;

  const getTotalReachForCampaigns = (): number => {
    const uniqueInfluencers = new Map<string, number>();
    
    Object.values(collaborations || {}).forEach(collab => {
      if (collab.status === "collabCompleted") {
        const influencer = influencers[collab.influencerID];
        if (influencer) {
          uniqueInfluencers.set(collab.influencerID, influencer.follower_count);
        }
      }
    });

    const reach = Array.from(uniqueInfluencers.values())
      .reduce((total, followerCount) => {
        return total + Number(followerCount);
      }, 0);

    return reach;
  };

  const [totalReach, setTotalReach] = useState<number>(0);

  useEffect(() => {
    const reach = getTotalReachForCampaigns();
    setTotalReach(reach);
  }, [influencerCampaigns]);


  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }


  const calculateCompletionRate = () => {
    return totalCreators > 0 ? Math.round((completedCollaborations / totalCreators) * 100) : 0;

   }
  

  const completionRate = calculateCompletionRate().toString() + '%';

  const boxContents = [
    { 
      label: 'Collabs', 
      value: totalCreators.toString(), 
      completed: completedCollaborations.toString(),
      icon: FaUsers
    },
    { 
      label: 'Total Posts', 
      value: getTotalPosts().toString(), 
      trend: getTotalPosts().toString(),
      icon: FaListUl
    },
    { 
      label: 'Estimated Reach', 
      value: formatFollowersCount(totalReach), 
      trend: formatFollowersCount(totalReach),
      icon: FaEye
    },
    { 
      label: 'Completion Rate', 
      value: completionRate, 
      trend: completionRate,
      icon: FaChartLine
    },
  ];

  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4} width="100%">
      {boxContents.map(({ label, value, completed, trend, icon }, index) => (
        <Box
          key={index}
          bg="white"
          borderRadius="xl"
          p={3.5}
          position="relative"
          border="1px solid"
          borderColor="gray.300"
          height="auto"
        >
          <Flex justify="space-between" align="start" mb={1.5}>
            <Text
              fontSize="14px"
              color="gray.700"
              fontWeight="500"
              letterSpacing="-0.2px"
            >
              {label}
            </Text>
            <Box
              bg="green.50"
              p={1.5}
              borderRadius="md"
              width="32px"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={icon}
                color="green.500"
                w={4}
                h={4}
              />
            </Box>
          </Flex>
          
          <Flex align="center" gap={2}>
            <Text
              fontSize="28px"
              fontWeight="600"
              color="gray.900"
              letterSpacing="-1px"
              lineHeight="1"
              mb={0.5}
            >
              {value}
            </Text>
            
            {trend && (
              <Flex
                align="center"
                bg="green.100"
                color="green.700"
                px={2}
                py={0.5}
                borderRadius="full"
                fontSize="12px"
                fontWeight="500"
                height="fit-content"
                gap={1}
              >
                <Icon as={FaArrowUp} w={3} h={3} />
                {trend}
              </Flex>
            )}
          </Flex>
          
          {completed && (
            <Text
              fontSize="13px"
              color="gray.500"
              mt={0.5}
            >
              {completed} Completed
            </Text>
          )}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default Analytics;