import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  Icon,
  Box,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import { FaShippingFast } from 'react-icons/fa';
import { Timestamp } from 'firebase/firestore';
import { updateShippingConfirmed } from 'services/firebaseService';
import { notifyInfluencerShipment } from 'services/flaskService';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';
import ShipmentInfoView from './ShipmentInfoView';

interface ShippingConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  collabId: string;
  onAfterConfirm: () => void;
  campaign?: InfluencerCampaign;
  influencer?: Influencer;
  collab?: CollaborationsData;
}

const ShippingConfirmationModal: React.FC<ShippingConfirmationModalProps> = ({
  isOpen,
  onClose,
  campaign,
  influencer,
  collab,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    if (collab?.id && !isLoading) {
      try {
        setIsLoading(true);
        await updateShippingConfirmed(collab.id);
        onClose();
        notifyInfluencerShipment(collab.id);
      } catch (error) {
        console.error('Error updating collab shipping status:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(10px)" />
      <ModalContent mx={4} bg="white" borderRadius="xl" boxShadow="xl">
        <ModalCloseButton color="black" />
        <ModalHeader 
          textAlign="center" 
          fontSize="2xl" 
          fontWeight="bold"
          pt={8}
          color="black"
        >
          Confirm Shipment
        </ModalHeader>
        <ModalBody>
          <VStack spacing={6} align="stretch" py={4}>
            <ShipmentInfoView
              campaign={campaign}
              influencer={influencer}
              collab={collab}
            />
            
            <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
              <Icon 
                as={FaShippingFast} 
                w={12} 
                h={12} 
                color="black" 
                mb={4}
              />
              <Text
                fontSize="lg"
                color="gray.700"
                maxW="md"
                lineHeight="tall"
                textAlign="center"
              >
                We will notify the influencer that their package is on the way. Please add tracking number(s) once they become available.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter pb={8} display="flex" justifyContent="center">
          <Button
            bg="black"
            color="white"
            width="90%"
            onClick={handleConfirm}
            _hover={{ bg: 'gray.800', transform: 'translateY(-1px)' }}
            transition="all 0.2s"
            isDisabled={isLoading}
            leftIcon={isLoading ? <Spinner size="sm" /> : undefined}
          >
            {isLoading ? 'Confirming...' : 'Confirm Shipment'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShippingConfirmationModal;
