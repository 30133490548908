import React, { useContext, useState } from 'react';
import {
  Box,
  Text,
  Progress,
  Flex,
} from '@chakra-ui/react';
import { InfluencerCampaign } from '../types';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';


interface ActiveCampaignsTableProps {
  clientId: string;
  onCampaignClick: (campaignId: string) => void;
}

const ActiveCampaignsTable: React.FC<ActiveCampaignsTableProps> = ({clientId, onCampaignClick}) => {

  const influencerCampaigns = useContext(InfluencerCampaignsContext);
 
  const collabs = useContext(CollabsDataContext);

  const getCampaignCollaborations = (campaignId: string) => {
    return Object.values(collabs).filter(collab => collab.influencerCampaignID === campaignId);
  };

  const getActiveCreators = (influencerCampaignId: string) => {
    const collaborations = getCampaignCollaborations(influencerCampaignId);
    if (!collaborations) return 0;

    return collaborations.length;
  };

  const calculateProgress = (influencerCampaignId: string) => {
    const collaborations = getCampaignCollaborations(influencerCampaignId);
    if (!collaborations) return 0;

    const completedCollaborations = collaborations.filter(
      collab => collab.status == "collabCompleted"
    );

    return collaborations.length > 0
      ? Math.round((completedCollaborations.length / collaborations.length) * 100)
      : 0;
      
  };

  const getTotalPosts = (influencerCampaignId: string) => {
    const collaborations = getCampaignCollaborations(influencerCampaignId);
    if (!collaborations) return 0;

    const completedCollaborations = collaborations.filter(
      collab => collab.status === "collabCompleted"
    );

    return completedCollaborations.reduce((total, collab) => {
      const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
      return total + instagramPosts + instagramReels + instagramStories + tiktoks;
    }, 0);
  };

  const handleRowClick = (campaign: InfluencerCampaign) => {
    onCampaignClick(campaign.id);
  };

  const sortedCampaigns = Object.values(influencerCampaigns || {})
    .sort((a, b) => calculateProgress(b.id) - calculateProgress(a.id));

  return (
    <Box 
      bg="white" 
      borderRadius="xl" 
      width="100%"
      mt={4}
      border="1px solid" 
      borderColor="gray.300"
      position="relative"
      zIndex={2}
      height="240px"
      display="flex"
      flexDirection="column"
    >
      <Text 
        fontSize="lg" 
        fontWeight="600" 
        mb={4}
        px={4}
        pt={2}
      >
        Active Campaigns
      </Text>
      
      <Box pb={4} overflowY="auto" flex="1">
        <Box
          bg="#F9F9F9"
          position="sticky"
          top={0}
          zIndex={1}
          width="100%"
        >
          <Flex 
            py={2}
            px={4}
            mb={1}
          >
            <Text flex="2" color="gray.600" fontSize="sm" fontWeight="500" textAlign="left">
              CAMPAIGN NAME
            </Text>
            <Text flex="1" color="gray.600" fontSize="sm" fontWeight="500" textAlign="left">
              TOTAL POSTS
            </Text>
            <Text flex="1" color="gray.600" fontSize="sm" fontWeight="500" textAlign="left">
              CREATORS
            </Text>
            <Text flex="1" color="gray.600" fontSize="sm" fontWeight="500" textAlign="left">
              PROGRESS
            </Text>
          </Flex>
        </Box>

        <Box px={4}>
          {sortedCampaigns.length > 0 ? sortedCampaigns.map((influencerCampaign) => (
            <Flex 
              key={influencerCampaign.id}
              py={3} // Increased padding from 2 to 3
              alignItems="center"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              borderRadius="lg"
              onClick={() => handleRowClick(influencerCampaign)}
             
            >
              <Text flex="2" fontWeight="500" textAlign="left">
                {influencerCampaign.name}
              </Text>
              <Text flex="1" textAlign="left">
                {getTotalPosts(influencerCampaign.id)}
              </Text>
              <Text flex="1" textAlign="left">
                {getActiveCreators(influencerCampaign.id)}
              </Text>
              <Flex flex="1" alignItems="center" gap={3} justifyContent="flex-start">
                <Text>{calculateProgress(influencerCampaign.id)}%</Text>
                <Progress 
                  value={calculateProgress(influencerCampaign.id)} 
                  size="sm" 
                  colorScheme="green" 
                  width="100px"
                  borderRadius="full"
                  bg="gray.100"
                />
              </Flex>
            </Flex>
          )) : (
            <Flex 
              direction="column" 
              align="flex-start" 
              py={6}
              color="gray.500"
            >
              <Text>Active campaigns will show up here</Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveCampaignsTable;