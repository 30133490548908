import React, { useState, useEffect, useContext } from 'react';
import { useToast } from "@chakra-ui/react";
import {
  Box,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import InstagramProfilePic from '../../components/InstagramProfilePic';
import { FaInstagram, FaStar, FaTiktok, FaCalendarAlt } from 'react-icons/fa';
import SubmissionsModal from '../../components/SubmissionsModal';
import CreateCampaignModal from '../../components/CreateCampaignModal';
import { Influencer, CollaborationsData, InfluencerCampaign, collabStates } from '../../types';
import CollabCalendar from '../../components/CollabCalendar';
import { refreshPostMetrics } from 'services/flaskService';
import CounteredCollabOptions from '../../components/counter-flow/CounterCollabOptions';
import { AccountsDataContext } from 'contexts/accountsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import StatusButton from '../../components/StatusButton';
import CollabDetailsView from '../../components/CollabDetailsView';
import CollabInviteView from '../../components/CollabInviteView';
import RejectedCollabModal from '../../components/RejectedCollabModal';

interface ActiveCampaignProps {
  clientID: string;
  campaignId: string;
}
interface postMetric {
  caption: string;
  like_count: number;
  comment_count: number;
  current_date: Date;
  user_link: string;
  play_count: string;
}

const ActiveCampaign: React.FC<ActiveCampaignProps> = ({
  clientID,
  campaignId
}) => {
  const history = useHistory();
  const [isSubmissionsModalOpen, setIsSubmissionsModalOpen] = useState(false);
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [selectedCollaboration, setSelectedCollaboration] = useState<CollaborationsData | null>(null);
  const [upcomingCollabsCount, setUpcomingCollabsCount] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const toast = useToast();
  const accountsData = useContext(AccountsDataContext);
  const influencers = useContext(InfluencersDataContext);
  const clientInfo = accountsData[clientID];
  const clientName = clientInfo?.accountName;

  const influencerCampaignsData = useContext(InfluencerCampaignsContext);
  const campaign = influencerCampaignsData[campaignId];
  
  const collabsData = useContext(CollabsDataContext);
  const collaborations: Record<string, CollaborationsData> = Object.values(collabsData).filter(collab => collab.influencerCampaignID === campaignId).reduce((acc, collab) => ({...acc, [collab.id]: collab}), {});
  
  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + 'k';
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + 'M';
    }
    return followers.toString();
  };

  const calculateMetrics = () => {
    let likes = 0;
    let comments = 0;
    let posts = 0;
    let totalFollowers = 0;

    Object.values(collaborations).forEach(collab => {
      if (collab.postMetrics) {
        Object.values(collab.postMetrics).forEach((metric: unknown) => {
          if (typeof metric === 'object' && metric !== null) {
            const postMetric = metric as postMetric;
            likes += postMetric.like_count || 0;
            comments += postMetric.comment_count || 0;
            posts += 1;
          }
        });
      }
      const influencer = influencers[collab.influencerID];
      if (influencer) {
        totalFollowers += influencer.follower_count || 0;
      }
    });

    const engagementRate = totalFollowers > 0 ? Math.min(((likes + comments) / totalFollowers), 1) : 0;

    return [
      { label: 'Posts', value: formatFollowersCount(posts) },
      { label: 'Likes', value: formatFollowersCount(likes) },
      { label: 'Comments', value: formatFollowersCount(comments) },
      { label: 'Engagement Rate', value: (engagementRate * 100).toFixed(2) + '%' },
    ];
  };

  const boxContents = calculateMetrics();


  useEffect(() => {
    const refreshMetrics = async () => {
      setIsRefreshing(true);
      try {
        const collabIds = Object.keys(collaborations);
        const data = await refreshPostMetrics(collabIds);
        console.log('Post metrics refreshed successfully:', data);
        
      } catch (error) {
        console.error('Error refreshing post metrics:', error);
        
      } finally {
        setIsRefreshing(false);
      }
    };

    refreshMetrics();
  }, []);

  const handleViewSubmissions = (collaboration: CollaborationsData) => {
    setSelectedCollaboration(collaboration);
    setIsSubmissionsModalOpen(true);
  };

  const handleViewDetails = () => {
    setIsCreateCampaignModalOpen(true);
  };

  const sortedCollaborations = Object.values(collaborations).sort((a, b) => {
    const priorityStatuses = ['collabCompleted'];
    if (priorityStatuses.includes(a.status) && !priorityStatuses.includes(b.status)) {
      return -1;
    }
    if (!priorityStatuses.includes(a.status) && priorityStatuses.includes(b.status)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box bg="white" overflowY="auto" p={0} display="flex" flexDirection="column" minHeight="calc(100vh - 135px)">
      <Box width="90%" mx="auto" my={6} display="flex" flexDirection="column" height="calc(100vh - 48px)">
        <Flex padding={7} position="relative" justifyContent="space-between" h="20%" bg="#B8E79E" borderRadius="lg" mb={6}>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color="black"            
          >
            {campaign.name}
          </Text>
          <Flex gap={2}>
            <Button
              bg="#EAFBE0"
              color="black"
              _hover={{ bg: "gray.300" }}
              onClick={handleViewDetails}
            >
              View Details
            </Button>
            <Box position="relative">
                <IconButton
                  aria-label="View Calendar"
                  icon={<FaCalendarAlt />}
                  bg="#EAFBE0"
                  _hover={{ bg: "gray.300" }}
                  onClick={() => setIsCalendarModalOpen(true)}
                  size="md"
                />
                {upcomingCollabsCount > 0 && (
                  <Box
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    width="20px"
                    height="20px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="xs"
                    fontWeight="bold"
                  >
                    {upcomingCollabsCount}
                  </Box>
                )}
              </Box>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: "gray.800" }}
              onClick={() => history.push(`/admin/foodfluence/search?campaignID=${campaign.id}&clientID=${clientID}`)}
            >
              Add Influencers
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent="center" mt="-8%" mb={6}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6} width="95%">
            {boxContents.map(({ label, value }, index) => (
              <Box
                key={index}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                p={6}
                position="relative"
                height={{ base: '130px', md: '150px' }}
              >
                <VStack align="flex-start" spacing={3} height="100%">
                  <Text
                    fontSize={{ base: 'sm', md: 'md' }}
                    fontWeight="bold"
                    color="gray.700"
                  >
                    {label}
                  </Text>
                  <VStack align="flex-start" spacing={0} mt="auto">
                    <HStack spacing={2} alignItems="baseline">
                      <Text
                        fontSize={{ base: '2xl', md: '3xl' }}
                        fontWeight="bold"
                        color="black"
                      >
                        {value}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="black"
          mb={4}
          pl={4}
        >
          Collaborations
        </Text>
        <Box overflowY="auto" flex={1} px={2} mb={8}>
          <Table variant="simple">
            <Tbody>
              {sortedCollaborations.map((collaboration) => {
                const influencer = influencers[collaboration.influencerID];
                return (
                  <Tr key={collaboration.id}>
                    <Td py={4}>
                      <Flex alignItems="center" gap={6}>
                        <InstagramProfilePic
                          profile_pic_url={influencer?.profile_pic_url}
                          instagramHandle={influencer?.instagramHandle}
                          imageSize="89px"
                        />
                        <VStack align="flex-start" spacing={1}>
                          <Flex alignItems="center" gap={2}>
                            <Text fontWeight="bold">@{influencer?.instagramHandle}</Text>
                            <Flex gap={1}>
                              {influencer?.featured && 
                                (<IconButton icon={<FaStar color="#0080FE" size={16} />} aria-label="Featured" variant="ghost" colorScheme="green" />
                              )}
                              <IconButton icon={<FaInstagram size={16} />} aria-label="Instagram" variant="ghost" colorScheme="gray" as="a" href={`https://instagram.com/${influencer?.instagramHandle}`} target="_blank" rel="noopener noreferrer" />
                              {influencer?.tiktokHandle && (
                                <IconButton icon={<FaTiktok size={16} />} aria-label="TikTok" variant="ghost" colorScheme="gray" as="a" href={`https://www.tiktok.com/@${influencer?.tiktokHandle}`} target="_blank" rel="noopener noreferrer" />
                              )}
                            </Flex>
                          </Flex>
                          <Text fontSize="sm" color="gray.600">
                            {influencer?.city && influencer?.state ? `${influencer.city}, ${influencer.state} | ` : ''}
                            {formatFollowersCount(influencer?.follower_count || 0)} followers
                          </Text>
                        </VStack>
                      </Flex>
                    </Td>
                    <Td textAlign="right">
                      <StatusButton
                        status={collaboration.status}
                        collaboration={collaboration}
                        campaign={campaign}
                        influencer={influencer}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <CreateCampaignModal
        isOpen={isCreateCampaignModalOpen}
        onClose={() => setIsCreateCampaignModalOpen(false)}
        clientID={clientID}
        campaignData={campaign}
      />
      <CollabCalendar
        campaignId={campaignId}
        isOpen={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        setUpcomingCollabsCount={setUpcomingCollabsCount}
        global={false}
      />
      {isRefreshing && <Text textAlign="center">Refreshing metrics...</Text>}
    </Box>
  );
};

export default ActiveCampaign;