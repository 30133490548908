import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useBreakpointValue,
  Container
} from "@chakra-ui/react";
import { useHistory, useRouteMatch, useParams, Switch, Route } from 'react-router-dom';
import WelcomeBanner from "./components/WelcomeBanner";
import ActiveCampaignsTable from './components/ActiveCampaignsTable';
import FeaturedInfluencers from "./components/FeaturedInfluencers";
import Search from './pages/search';

import AccountsDropdown from "./components/accountsDropDown";

import { AccountsDataContext } from "contexts/accountsDataContext";
import ActiveCampaign from "./pages/ActiveCampaign";
import UpcomingCollabList from "./components/UpcomingCollabsList";
import CounteredCollabsList from "./components/counter-flow/CounteredCollabsList";
import CollabCalendar from './components/CollabCalendar';
import CounterCalendar from './components/counter-flow/CounterCalendar';
import CollabsDataProvider, { CollabsDataContext } from "contexts/collabsDataContext";
import InfluencerCampaignsProvider, { InfluencerCampaignsContext } from "contexts/influencerCampaignsDataContext";
import FoodfluenceLocationsDataProvider from "contexts/foodfluenceLocationsDataContext";
import InfluencersDataProvider from "contexts/influencersDataContext";


export default function FoodFluence() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [filteredClientID, setFilteredClientID] = useState<string>("");
  const [selectedAccountName, setSelectedAccountName] = useState<string>("");
  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const accountsData = useContext(AccountsDataContext);

  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [calendarInitialTab, setCalendarInitialTab] = useState(0);


  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${filteredClientID}`);
  };

  const handleCampaignClick = (campaignId: string) => {
    history.push(`${path}/campaign/${campaignId}`);
  };

  let clientAccounts = [];
  for (let account in accountsData) {
    if (account && accountsData[account]) {
      clientAccounts.push({
        ...accountsData[account],
      });
    }
  }
  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const FoodFluenceMain = () => {
    const [isCounteredModalOpen, setIsCounteredModalOpen] = useState(false);

    return (
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="10px"
          minHeight="calc(100vh - 135px)"
          position="relative"
          display="flex"
          flexDirection="column"
          p={{ base: 2, md: 3 }}
        >
          <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
            <Box flex="1" ml = {-0} width="auto">
              <WelcomeBanner 
                clientID={filteredClientID} 
                accountName={selectedAccountName} 
              />
            </Box>
            {accountsData && Object.keys(accountsData)?.length > 0 && (
              <Box width={{ base: "100%", md: "auto" }} mt={{ base: 1, md: 0 }}>
                <AccountsDropdown
                  filteredClientId={filteredClientID}
                  clientAccounts={clientAccounts}
                  setFilteredClientId={setFilteredClientID}
                  setSelectedAccountName={handleAccountNameChange}
                  selectedAccountName={selectedAccountName}
                />
              </Box>
            )}
          </Flex>
          
          <Container 
            maxW="container.2xl" 
            position="relative"
            mt={-6}
            px={{ base: 6, md: 8 }}
            zIndex={2}
          >
            <Box 
              width="95%" 
              mx="auto"
            >
              <ActiveCampaignsTable 
                clientId={filteredClientID}
                onCampaignClick={handleCampaignClick}
              />
            </Box>
          </Container>
          
          <Container 
            maxW="container.2xl" 
            px={{ base: 6, md: 8 }}
          >
            <Box 
              width="95%" 
              mx="auto"
            >
              <Flex gap={8} mt={4}>
                <Box width="26%">
                  <UpcomingCollabList 
                    onViewAll={handleViewAllCollabs}
                  />
                </Box>
                <Box width="26%">
                  <CounteredCollabsList
                    clientId={filteredClientID}
                    onViewAll={() => setIsCounteredModalOpen(true)}
                    clientName={selectedAccountName}
                  />
                </Box>
                <Box width="48%">
                  <FeaturedInfluencers 
                    clientID={filteredClientID} 
                    featuredInfluencers={[]}
                  />
                </Box>
              </Flex>
            </Box>
          </Container>

          <CollabCalendar
            campaignId={null}
            isOpen={isCalendarModalOpen}
            onClose={() => {
              setIsCalendarModalOpen(false);
              setCalendarInitialTab(0);
            }}
            global={false}
            businessType={accountsData[filteredClientID]?.businessType}
            initialTab={calendarInitialTab}
            setUpcomingCollabsCount={(count) => {}}
          />
          <CounterCalendar
            clientId={filteredClientID}
            setCounteredCollabsCount={(count) => {}}
            isOpen={isCounteredModalOpen}
            onClose={() => setIsCounteredModalOpen(false)}
            global={false}
            clientName={selectedAccountName}
          />
        </Box>
      </Box>
    );
  };

  const ActiveCampaignWrapper = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    
    if (!campaignId) {
      return <div>Campaign not found</div>;
    }



    return (
      <ActiveCampaign
        clientID = {filteredClientID}
        campaignId = {campaignId}
      />
    );
  };

  const handleViewAllCollabs = (tabIndex: number = 0) => {
    setCalendarInitialTab(tabIndex);
    setIsCalendarModalOpen(true);
  };

  return (
    <InfluencerCampaignsProvider clientId={filteredClientID}>
      <CollabsDataProvider>
        <FoodfluenceLocationsDataProvider>
          <InfluencersDataProvider>
          <Switch>
            <Route exact path={path}>
          <FoodFluenceMain />
          </Route>
          <Route path={`${path}/search`}>
          <Search/>
          </Route>
          <Route path={`${path}/campaign/:campaignId`}>
              <ActiveCampaignWrapper />
          </Route>
          </Switch>
          </InfluencersDataProvider>
        </FoodfluenceLocationsDataProvider>
      </CollabsDataProvider>
    </InfluencerCampaignsProvider>

  );
}