import { CollaborationsData, InfluencerCampaign } from '../types';
import moment from 'moment';

// Sorting functions
export const sortByDate = (collabs: CollaborationsData[]): CollaborationsData[] => {
  return [...collabs].sort((a, b) => {
    if (!a.scheduledCollabDate && !b.scheduledCollabDate) return 0;
    if (!a.scheduledCollabDate) return 1;
    if (!b.scheduledCollabDate) return -1;
    return moment(a.scheduledCollabDate.toDate()).diff(moment(b.scheduledCollabDate.toDate()));
  });
};

export const sortByCounteredTime = (collabs: CollaborationsData[]): CollaborationsData[] => {
  return [...collabs].sort((a, b) => {
    if (!a.collabCounteredTime && !b.collabCounteredTime) return 0;
    if (!a.collabCounteredTime) return 1;
    if (!b.collabCounteredTime) return -1;
    return moment(a.collabCounteredTime.toDate()).diff(moment(b.collabCounteredTime.toDate()));
  });
};

export const sortByShippingStatus = (collabs: CollaborationsData[]): CollaborationsData[] => {
  return [...collabs].sort((a, b) => {
    // First sort by shipping (shipping collabs first)
      if (a.shippingAddress && !b.shippingAddress) return -1;
      if (!a.shippingAddress && b.shippingAddress) return 1;
      
      // Then sort by date
      if (!a.scheduledCollabDate && !b.scheduledCollabDate) return 0;
      if (!a.scheduledCollabDate) return 1;
      if (!b.scheduledCollabDate) return -1;
      return moment(a.scheduledCollabDate.toDate()).diff(moment(b.scheduledCollabDate.toDate()));
    });
};

export const filterUpcomingCollabs = (collaborations: Record<string, CollaborationsData>): CollaborationsData[] => {
  if (!collaborations) return [];
  
  const filtered = Object.values(collaborations).filter(collab => 
    collab.status === 'pendingRedemption' || 
    collab.status === 'collabRedeemed' ||
    (collab.status === 'pendingSubmission' && collab.shippingAddress && !collab.shippingConfirmed)
  );
  
  return sortByShippingStatus(filtered);
};

export const filterUnconfirmedCollabs = (collaborations: Record<string, CollaborationsData>): CollaborationsData[] => {
  if (!collaborations) return [];

  const filtered = Object.values(collaborations).filter(collab => 
    collab.status === 'pendingInfluencerApproval'
  );

  return sortByDate(filtered);
};

export const filterPastCollabs = (
  collaborations: Record<string, CollaborationsData>, 
  campaigns: Record<string, InfluencerCampaign>
): CollaborationsData[] => {
  if (!collaborations) return [];

  const filtered = Object.values(collaborations).filter(collab => 
    (collab.status === 'pendingSubmission' && 
     campaigns[collab.influencerCampaignID]?.campaignType !== "shipToHome") || 
    collab.status === 'collabCompleted'
  );

  return sortByDate(filtered);
};

export const filterCanceledCollabs = (collaborations: Record<string, CollaborationsData>): CollaborationsData[] => {
  if (!collaborations) return [];

  const filtered = Object.values(collaborations).filter(collab => 
    collab.status === 'collabCanceled' || collab.status === 'collabExpired'
  );

  return sortByDate(filtered);
};

export const filterAwaitingShipmentCollabs = (collaborations: Record<string, CollaborationsData>): CollaborationsData[] => {
  if (!collaborations) return [];

  const filtered = Object.values(collaborations).filter(collab => 
    collab.shippingAddress && collab.status === "pendingSubmission"
  );

  return sortByShippingStatus(filtered);
};

export const filterCollabsByCampaign = (collaborations: Record<string, CollaborationsData>, campaignId: string): Record<string, CollaborationsData> => {
  if (!collaborations) return {};

  return Object.values(collaborations).filter(collab => collab.influencerCampaignID === campaignId).reduce((acc, collab) => {
    acc[collab.id] = collab;
    return acc;
  }, {});
};