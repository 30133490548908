import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  Box,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { FiArrowUpCircle } from 'react-icons/fi';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UpgradeModal: React.FC<UpgradeModalProps> = ({ isOpen, onClose }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        bg={bgColor}
        borderRadius="2xl"
        boxShadow="2xl"
        border="1px solid"
        borderColor={borderColor}
        p={4}
      >
        <Box textAlign="center" py={4}>
          <Icon as={FiArrowUpCircle} w={16} h={16} color="black" mb={4} />
        </Box>
        <ModalHeader 
          textAlign="center" 
          fontSize="2xl" 
          fontWeight="bold"
          pb={2}
        >
          Time to Level Up! 🚀
        </ModalHeader>
        <ModalBody>
          <VStack spacing={6}>
            <Text align="center" fontSize="lg" color="gray.600">
              You've reached your collaboration limit. Upgrade now to unlock:
            </Text>
            <Box 
              w="full" 
              p={4} 
              bg="blue.50" 
              borderRadius="xl"
              border="1px dashed"
              borderColor="blue.200"
            >
              <VStack spacing={3}>
                <Text fontWeight="medium">✨ More Influencer Invites</Text>
                <Text fontWeight="medium">🤝 More Collaborations</Text>
                <Text fontWeight="medium">🔍 Curated Searches</Text>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center" pt={6}>
          <Button
            as="a"
            href="https://www.foodfluence.io/#new-price"
            target="_blank"
            bg="black"
            color="white"
            size="lg"
            onClick={onClose}
            rounded="full"
            px={12}
            py={6}
            fontSize="lg"
            fontWeight="bold"
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              bg: 'gray.800'
            }}
            transition="all 0.2s"
          >
            Upgrade Now
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
