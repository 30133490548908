import {
  Box,
  Text,
  Input,
  Textarea,
  Stack,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { CollaborationsData, InfluencerCampaign } from '../types';

interface CollabDetailsViewProps {
  campaign: InfluencerCampaign;
  deliverableCounts: {
    tiktoks: number;
    instagramStories: number;
    instagramReels: number;
    instagramPosts: number;
  };
  onDeliverableChange?: (type: string, value: number) => void;
  cash?: string;
  credit?: string;
  onCashChange?: (value: string) => void;
  onCreditChange?: (value: string) => void;
  isReadOnly?: boolean;
}

const CollabDetailsView: React.FC<CollabDetailsViewProps> = ({
  campaign,
  deliverableCounts,
  onDeliverableChange,
  cash,
  credit,
  onCashChange,
  onCreditChange,
  isReadOnly = false,
}) => {
  const handleInputChange = (type: string) => (value: number) => {
    if (onDeliverableChange) {
      if (value < 0) {
        onDeliverableChange(type, 0);
      } else {
        onDeliverableChange(type, value);
      }
    }
  };

  const handleNumericInput = (setter?: (value: string) => void) => (value: string) => {
    if (/^\d*$/.test(value) && setter) {
      setter(value);
    }
  };

  return (
    <Box mb={4}>
      <Text fontWeight="bold">Campaign Name</Text>
      <Input
        placeholder="Collaboration Name"
        value={campaign.name}
        isReadOnly={true}
        variant={true ? "readonly" : "chakra_input_default"}
        mb={4}
      />
      <Text fontWeight="bold">Describe the campaign</Text>
      <Textarea
        placeholder="What are you looking to create with this campaign?"
        value={campaign.description}
        isReadOnly={true}
        variant={true ? "readonly" : "main"}
        mb={4}
      />
      <Text fontWeight="bold" mb={2}>Content Types:</Text>
      <Stack spacing={4}>
        {[
          { label: 'TikTok', type: 'tiktoks', count: deliverableCounts.tiktoks },
          { label: 'Instagram Story', type: 'instagramStories', count: deliverableCounts.instagramStories },
          { label: 'Instagram Reel', type: 'instagramReels', count: deliverableCounts.instagramReels },
          { label: 'Instagram Post', type: 'instagramPosts', count: deliverableCounts.instagramPosts }
        ].map(({ label, type, count }) => (
          <Flex key={label} alignItems="center" justifyContent="space-between">
            <Text>{label}</Text>
            <Flex alignItems="center">
              {!isReadOnly && (
                <IconButton
                  icon={<FaMinus style={{ color: 'red' }} />}
                  aria-label={`Decrease ${label} count`}
                  onClick={() => handleInputChange(type)(count - 1)}
                  isDisabled={count <= 0}
                />
              )}
              <Text mx={2}>{count}</Text>
              {!isReadOnly && (
                <IconButton
                  icon={<FaPlus style={{ color: 'green' }} />}
                  aria-label={`Increase ${label} count`}
                  onClick={() => handleInputChange(type)(count + 1)}
                />
              )}
            </Flex>
          </Flex>
        ))}
      </Stack>
      <Box mt={4}>
        <FormControl>
          <FormLabel fontWeight="bold">Credit Amount ($):</FormLabel>
          <Input
            type="text"
            value={campaign.campaignType === "shipToHome" ? "Credit not accepted" : credit}
            onChange={(e) => handleNumericInput(onCreditChange)(e.target.value)}
            isReadOnly={isReadOnly || campaign.campaignType === "shipToHome"}
            variant={(isReadOnly || campaign.campaignType === "shipToHome") ? "readonly" : "chakra_input_default"}
            color={campaign.campaignType === "shipToHome" ? "gray.500" : "black"}
          />
        </FormControl>
      </Box>
      <Box mt={4}>
        <FormControl>
          <FormLabel fontWeight="bold">Cash Amount ($):</FormLabel>
          <Input
            type="text"
            value={cash}
            onChange={(e) => handleNumericInput(onCashChange)(e.target.value)}
            isReadOnly={isReadOnly}
            variant={isReadOnly ? "readonly" : "chakra_input_default"}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default CollabDetailsView;