import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Flex, Icon, VStack, HStack, Avatar } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';
import { CollaborationsData, InfluencerCampaign } from '../../types';
import moment from 'moment';
import CounteredCollabOptions from './CounterCollabOptions';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';

interface CounteredCollabsProps {
  onViewAll?: () => void;
  clientName: string;
  clientId: string;
}

const CounteredCollabs: React.FC<CounteredCollabsProps> = ({ onViewAll, clientName, clientId }) => {
  
  const [selectedCollab, setSelectedCollab] = useState<CollaborationsData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const allCollaborations = useContext(CollabsDataContext);
  const collabs = Object.values(allCollaborations).filter(collab => collab.status === 'influencerCountered');
  const influencers = useContext(InfluencersDataContext); 

  const handleCollabClick = (collab: CollaborationsData) => {
    setSelectedCollab(collab);
    setIsModalOpen(true);
  };

  const sortedCollabs = Object.values(collabs)
    .sort((a, b) => {
      if (!a.collabCounteredTime && !b.collabCounteredTime) return 0;
      if (!a.collabCounteredTime) return 1;
      if (!b.collabCounteredTime) return -1;
      return moment(a.collabCounteredTime.toDate()).diff(moment(b.collabCounteredTime.toDate()));
    });

  return (
    <Box 
      bg="white" 
      borderRadius="xl" 
      border="1px solid"
      borderColor="gray.300"
      width="100%"
      height="300px"
      overflow="hidden"
    >
      <Flex 
        justify="space-between" 
        align="center" 
        p={4} 
        borderBottom="1px solid" 
        borderColor="gray.100"
      >
        <Text fontSize="lg" fontWeight="600">
          Countered Collabs ({sortedCollabs.length})
        </Text>
        <Box
          as="button"
          display="flex"
          alignItems="center"
          gap={1}
          px={3}
          py={1}
          borderRadius="full"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          onClick={onViewAll}
        >
          <Text fontSize="sm" color="gray.600">All</Text>
          <Icon as={FaChevronRight} w={3} h={3} color="gray.600" />
        </Box>
      </Flex>

      <VStack 
        spacing={0} 
        align="stretch" 
        overflow="auto" 
        maxHeight="calc(300px - 65px)"
      >
        {sortedCollabs.map((collab) => {
          const influencer = influencers[collab.influencerID];
          return (
            <Flex
              key={collab.id}
              p={4}
              borderBottom="1px solid"
              borderColor="gray.100"
              _hover={{ bg: "gray.50" }}
              cursor="pointer"
              align="center"
              justify="space-between"
              onClick={() => handleCollabClick(collab)}
            >
              <HStack spacing={3}>
                <Avatar 
                  size="sm"
                  src={influencer?.profile_pic_url} 
                  name={influencer?.instagramHandle}
                />
                <Box>
                  <Text fontWeight="500">
                    @{influencer?.instagramHandle || 'Unknown'} 
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {collab.collabCounteredTime 
                      ? moment(collab.collabCounteredTime.toDate()).format('MMM D, h:mm A')
                      : 'Countered time unknown'}
                  </Text>
                </Box>
              </HStack>
            </Flex>
          );
        })}

        {sortedCollabs.length === 0 && (
          <Flex p={4} justify="center" align="center">
            <Text color="gray.500">No countered collaborations</Text>
          </Flex>
        )}
      </VStack>

      {selectedCollab && (
        <CounteredCollabOptions
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          collab={selectedCollab}
          closeCounteredCalendar={() => setIsModalOpen(false)}
          campaignId={selectedCollab?.influencerCampaignID}
          clientId={clientId}
          clientName={clientName}
        />
      )}
    </Box>
  );
};

export default CounteredCollabs;