import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, Spinner, Button, useBreakpointValue } from '@chakra-ui/react';
import { FaInstagram, FaTiktok, FaStar, FaChevronRight } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InstagramProfilePic from './InstagramProfilePic';
import { getFeaturedInfluencers } from 'services/firebaseService';
import { Influencer } from '../types';

interface FeaturedInfluencersProps {
  clientID: string;
  featuredInfluencers: Influencer[];
}

const FeaturedInfluencers: React.FC<FeaturedInfluencersProps> = ({ clientID }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const [featuredInfluencers, setFeaturedInfluencers] = useState<Influencer[]>([]);

  useEffect(() => {
    const fetchFeaturedInfluencers = async () => {
      try {
        const influencers = await getFeaturedInfluencers();
        setFeaturedInfluencers(influencers);
      } catch (error) {
        console.error("Error fetching featured influencers:", error);
      }
    };

    fetchFeaturedInfluencers();
  }, []);

  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + 'k';
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + 'M';
    }
    return followers.toString();
  };

  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${clientID}`);
  };

  return (
    <Box
      bg="white"
      borderRadius="xl"
      border="1px solid"
      borderColor="gray.300"
      width="100%"
      height="300px"
      overflow="hidden"
    >
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Text fontSize="xl" fontWeight="600">
          Featured Influencers
        </Text>
        <Box
          as="button"
          display="flex"
          alignItems="center"
          gap={1}
          px={3}
          py={1}
          borderRadius="full"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          onClick={handleSearchClick}
        >
          <Text fontSize="sm" color="gray.600">Search All</Text>
          <Box as={FaChevronRight} w={3} h={3} color="gray.600" />
        </Box>
      </Flex>

      <Flex
        overflowX="auto"
        p={4}
        alignItems="flex-start"
        gap={4}
        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
        maxWidth="100%"
        mx="auto"
        position="relative"
        height="calc(300px - 65px)"
      >
        {featuredInfluencers.length === 0 ? (
          <Flex
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          featuredInfluencers.map(influencer => (
            <Box
              key={influencer.id}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              pt={3}
              px={4}
              textAlign="center"
              width="300px"
              height="200px"
              flexDirection="column"
              gap={2}
            >
              <Flex direction="column" gap={1} alignItems="center" justifyContent="center">
                <InstagramProfilePic profile_pic_url={influencer.profile_pic_url}
                instagramHandle={influencer.instagramHandle}
                imageSize="70px"
                />
                <Flex alignItems="center" direction="row" justifyContent="center" gap={2}>
                  <Text fontSize="16px" fontWeight="normal" color="black">
                    {influencer.instagramHandle}
                  </Text>
                  <FaStar color="#0080FE" size={16} />
                </Flex>
                <Flex justifyContent="center" gap={4}>
                  <IconButton icon={<FaInstagram size={18} />} aria-label="Instagram" variant="ghost" colorScheme="gray" as="a" href={`https://instagram.com/${influencer.instagramHandle}`} target="_blank" rel="noopener noreferrer" />
                  {influencer.tiktokHandle && (
                    <IconButton icon={<FaTiktok size={18} />} aria-label="TikTok" variant="ghost" colorScheme="gray" as="a" href={`https://www.tiktok.com/@${influencer.tiktokHandle}`} target="_blank" rel="noopener noreferrer" />
                  )}
                </Flex>
                <Text color="gray.500" fontSize="14px">
                  {formatFollowersCount(influencer.follower_count)} followers
                </Text>
              </Flex>
            </Box>
          ))
        )}
      </Flex>
    </Box>
  );
};

export default FeaturedInfluencers;
